import { Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const SingleCarousel = () => {
  return (
    <div className="single-carousel-container">
      <Swiper
        className="swiper-single-carousel"
        navigation
        spaceBetween={16}
        breakpoints={{
          1440: {
            slidesPerView: 1,
          },
          1244: {
            slidesPerView: 1,
          },
          1100: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 1,
          },
          360: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
        }}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 1,
        }}
        modules={[Navigation, Lazy]}
      >
        <SwiperSlide>
          <a href="https://www.decathlon.com.br/circula/produtos/#1">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/circula-natal.png"
              alt="Circula | Decathlon"
              id="desk"
            />
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/circula-natal-mob.png"
              alt="Circula | Decathlon"
              id="mob"
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.decathlon.com.br/decathlon-itau/">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/itau-natal.png"
              alt="cartao decathlon itau | Decathlon"
              id="desk"
            />
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/itau-natal-mob.png"
              alt="cartao decathlon itau | Decathlon"
              id="mob"
            />
          </a>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default SingleCarousel
